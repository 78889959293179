import { useEffect, useRef, useState } from "react";
import { Companies, ScriptHistories } from "../../_interfaces";
import { ApiErrorResponse } from "../../_network/response";
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify';
import { FileUploadApi } from "_services/file-upload.services";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import { useFetch } from "hooks/useFetch";
import { SuperadminServices } from "_services";
import { Badge, Button, FileInput, Label, Modal, Select, Spinner, Table } from "flowbite-react";

const SplitBulkImportPage = () => {
  const [page, set_page] = useState(1)
  const getHistory = useFetch<ScriptHistories>(SuperadminServices.Script.Script_GetByPagination)
  useEffect(() => {
    getHistory.request("SPLIT_BULK_IMPORT", page, 100)
  }, [page])
  // 2. Hook for Updater
  const [create_modal, set_create_modal] = useState(false)
  const [output_modal, set_output_modal] = useState<{show: boolean, id: number}>({show: false, id:0})

  // https://flowbite.com/docs/components/tabs/
  return <>
    {
      create_modal && 
      <CreateModal onSuccess={() => getHistory.request("SPLIT_BULK_IMPORT", page, 100)} onClose={() => set_create_modal(false)} />
    }
    {
      output_modal.show && 
      <OutputModal id={output_modal.id} onClose={() => set_output_modal({show:false, id: 0})} />
    }

    <div className="mb-8 flex justify-end">
    <Button type="button" onClick={() => set_create_modal(true)} size={"sm"}>Create Script</Button>
    </div>
    <Table>
      <Table.Head>
        <Table.Cell>ID</Table.Cell>
        <Table.Cell>Tanggal</Table.Cell>
        <Table.Cell>Param</Table.Cell>
        <Table.Cell>Status</Table.Cell>
        <Table.Cell></Table.Cell>
      </Table.Head>
      <Table.Body>
      {getHistory.loading &&
        <Table.Row>
          <Table.Cell colSpan={5}><Spinner/></Table.Cell>
        </Table.Row>
        }
      {getHistory.data?.list.length === 0 &&
      <Table.Row>
        <Table.Cell colSpan={5}>Belum ada data</Table.Cell>
      </Table.Row>}
      {getHistory.data?.list.map((h) => 
        <Table.Row>
          <Table.Cell>#{h.id}</Table.Cell>
          <Table.Cell className="whitespace-nowrap">{moment(h.created_at).format("DD-MM-YYYY HH:mm")}</Table.Cell>
          <Table.Cell>{h.param}</Table.Cell>
          <Table.Cell><Badge color={ h.status === "DONE" ? "success" : (h.status === "ERROR" || h.status === "CANCEL") ? "failure" : "warning"} className="capitalize">{h.status.toLowerCase()}</Badge></Table.Cell>
          <Table.Cell><Button type="button" onClick={() => set_output_modal({show: true, id: h.id})} size={"xs"} > Output</Button></Table.Cell>
        </Table.Row>
      )}
      </Table.Body>
    </Table>
  </>


//   const upload_file_ref = useRef<HTMLInputElement>(null);
//   const [upload_file, set_upload_file] = useState<Blob>();
//   const [submitting, setSubmitting] = useState(false)
//   const [upload_progress, set_upload_progress] = useState(0)

//   const getCompany = useFetch<Companies>(SuperadminServices.getCompanyByPagination)
//   const getScriptSplitBulkImport = useFetch<void>(SuperadminServices.Script.Script_SplitBulkImport);

//   useEffect(() => {
//     getCompany.request("", 1, 1000)
//   }, [])
//   useEffect(() => {
//     if(getCompany.data && getCompany.data.companies.length > 0){
//       setValue("companyId", getCompany.data.companies[0].id, {shouldValidate: true})
//     }
//   }, [getCompany.data])
//   // 2. Hook for Updater
//   // const [create_modal, set_create_modal] = useState(false)
//   // const [output_modal, set_output_modal] = useState<{show: boolean, id: number}>({show: false, id:0})
//   const ValidationSchema = yup.object().shape({
//     companyId: yup.string().required(),
//     fileUrl: yup.string(),
//   })
// const { register, handleSubmit, formState: { errors  }, setError, setValue } = useForm<{companyId: string, fileUrl: string}>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });

// const onSubmitHandler = async (req: {companyId: string, fileUrl: string}) => {
//   try {
//     setSubmitting(false)

//     if (upload_file !== undefined) {
//       if (upload_file.type !== 'text/csv' && upload_file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  && upload_file.type !== 'application/vnd.ms-excel') {
//         toast.error('invalid image type, required: csv or xlsx');
//         setError('fileUrl', { message: 'invalid image type, required: csv' });
//         return;
//       } else if (upload_file.size > 1024 * 1024 * 10) {
//         toast.error('maximum image: 10MB');
//         setError('fileUrl', { message: 'maximum image: 10MB' });
//         return;
//       }
//       set_upload_progress(0);
//       const path = `${process.env.REACT_APP_S3_PARENT_FOLDER}/superadmin-script/split_bulk_import`;
//       const filename = `splitbulkimport_${moment().format('YYYY-MM-DD')}_${uuidv4()}}.xlsx`;
//       const s3_file = await FileUploadApi.S3Upload(upload_file, path + '/' + filename, p => {
//         set_upload_progress(p);
//       });
//       if (s3_file) {
//         req.fileUrl = s3_file.Location;
//       }
//     }

//     if(req.fileUrl === undefined || req.fileUrl === "") {
//       setError('fileUrl', { message: "file is required" }, {shouldFocus: true})
//       return
//     }

//     console.log(req)
//     await getScriptSplitBulkImport.request(req.companyId, req.fileUrl)
//   } catch (error) {
//     if(error as ApiErrorResponse){
//       toast.error((error as ApiErrorResponse).message)
//     } else {
//       console.log("Unknown error:",error);
//       toast.error("Internal Error")
//     }
//   } finally {
//     setSubmitting(false)
//   }
// };
//   return <>
//     <form key="lbf-form" className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
//       <div>
//         <div className="mb-2 block"> <Label htmlFor="companyId" value="Company ID"/></div>
//         <Select id="companyId" { ...register('companyId')}>
//           { getCompany.data?.companies.map((c) => 
//           <option key={`company-${c.id}`} value={c.id}>{c.id} - {c.name}</option>
//           )}
//         </Select>
//         <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.companyId && <>{errors.companyId.message}</>)  }</p>
//       </div>
      
//       <div>
//         <div className="mb-2 block"> <Label htmlFor="file" value="File Bulk Import"/></div>
//         <FileInput
//           ref={upload_file_ref}
//           accept="text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
//           onChange={e => {
//             if (e.target.files) {
//               if (e.target.files.length > 0) {
//                 set_upload_file(e.target.files[0]);
//               } else {
//                 set_upload_file(undefined);
//               }
//             }
//           }}
//         />
//         <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors?.fileUrl && <>{errors.fileUrl.message}</>}</p>
          
//         {/* <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.amountFrom && <>{errors.amountFrom.message}</>)  }</p> */}
//       </div>
//       <div className="flex justify-center">
//         <Button type="submit" disabled={submitting}>
//           Submit {submitting && <Spinner/>}
//         </Button>
     
//       </div>
//     </form>

//   </>
};



export default SplitBulkImportPage


const CreateModal = (props: { onSuccess: () => void, onClose: () => void }) => {
  const upload_file_ref = useRef<HTMLInputElement>(null);
  const [upload_file, set_upload_file] = useState<Blob>();

  const getCompany = useFetch<Companies>(SuperadminServices.getCompanyByPagination)
  useEffect(() => {
    getCompany.request("", 1, 1000)
  }, [])

  const ValidationSchema = yup.object().shape({
      companyId: yup.string().required(),
      file: yup.string(),
  })

  const [submitting, setSubmitting] = useState(false)
  const [upload_progress, set_upload_progress] = useState(0)
  
  const { register, handleSubmit, formState: { errors  }, setError } = useForm<{companyId: string, file: string}>({ mode: 'onChange', resolver: yupResolver(ValidationSchema) });

  const onSubmitHandler = async (data: {companyId: string, file: string}) => {
    try {
      setSubmitting(false)

      if (upload_file !== undefined) {
        if (upload_file.type !== 'text/csv' && upload_file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  && upload_file.type !== 'application/vnd.ms-excel') {
          toast.error('invalid image type, required: csv or xlsx');
          setError('file', { message: 'invalid image type, required: csv' });
          return;
        } else if (upload_file.size > 1024 * 1024 * 10) {
          toast.error('maximum image: 10MB');
          setError('file', { message: 'maximum image: 10MB' });
          return;
        }
        set_upload_progress(0);
        const path = `${process.env.REACT_APP_S3_PARENT_FOLDER}/superadmin-script/split_bulk_import`;
        const filename = `splitbulkimport_${moment().format('YYYY-MM-DD')}_${uuidv4()}.xlsx`;
        const s3_file = await FileUploadApi.S3Upload(upload_file, path + '/' + filename, p => {
          set_upload_progress(p);
        });
        if (s3_file) {
          data.file = s3_file.Location;
        }
      }

      if(data.file === undefined || data.file === "") {
        setError('file', { message: "file is required" }, {shouldFocus: true})
        return
      }

      await SuperadminServices.Script.Script_SplitBulkImport(data.companyId, data.file)
      toast.success("Script run")
      props.onClose()
      props.onSuccess()
    } catch (error) {
      if(error as ApiErrorResponse){
        toast.error((error as ApiErrorResponse).message)
      } else {
        console.log("Unknown error:",error);
        toast.error("Internal Error")
      }
    } finally {
      setSubmitting(false)
    }
  };
  
  return <>
      <Modal show={true} onClose={ ()=> { props.onClose() }} >
          <Modal.Header>Run Update Salary</Modal.Header>
          <Modal.Body>
            <form key="create-form" className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
              <div>
                <div className="mb-2 block"> <Label htmlFor="companyId" value="Company ID"/></div>
                <Select id="companyId" { ...register('companyId')}>
                  { getCompany.data?.companies.map((c) => 
                  <option key={`company-${c.id}`} value={c.id}>{c.id} - {c.name}</option>
                  )}
                </Select>
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{(errors?.companyId && <>{errors.companyId.message}</>)  }</p>
              </div>

              <FileInput
                ref={upload_file_ref}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                onChange={e => {
                  if (e.target.files) {
                    if (e.target.files.length > 0) {
                      set_upload_file(e.target.files[0]);
                    } else {
                      set_upload_file(undefined);
                    }
                  }
                }}
              />
              {/* <p className="text-xs">
                Update salary file. Example: 
                <a href="https://storage.googleapis.com/gajiku-jenkins/update_monthly_limit_template.csv" target="_blank" rel="noreferrer" className="hover:underline">
                  https://storage.googleapis.com/gajiku-jenkins/update_monthly_limit_template.csv
                </a>
              </p> */}
              <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors?.file && <>{errors.file.message}</>}</p>
              
              <div className="flex items-center justify-center">
              <Button type="submit" disabled={submitting}>
                Submit {submitting && <Spinner/>}
              </Button>
              </div>
            </form> 
          </Modal.Body>
      </Modal>
  </>
}

const OutputModal = (props: { id: number, onClose: () => void }) => {
  const getOutput = useFetch<{output: string}>(SuperadminServices.Script.Script_GetOutput)
  useEffect(() => {
    getOutput.request(props.id)
  }, [])

  return <>
      <Modal size={"7xl"} show={true} onClose={ ()=> { props.onClose() }} >
          <Modal.Header>Output Console</Modal.Header>
          <Modal.Body>
            <pre className="overflow-auto text-xs">
              {getOutput.data === undefined ? 
              <><Spinner /></>:
              <code>{getOutput.data.output}</code>}
            </pre>
          </Modal.Body>
      </Modal>
  </>
}